import { PROJECT_MEMBER_ROLES } from 'src/app/constants';

export const TENANT_USER_ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  STANDARD: 'standard',
} as const;

export type TenantUserRole = (typeof TENANT_USER_ROLES)[keyof typeof TENANT_USER_ROLES];

export const PROJECT_MEMBER_ROLE_ICONS = {
  [PROJECT_MEMBER_ROLES.OWNER]: 'shield-checkmark-outline',
  [PROJECT_MEMBER_ROLES.AGENT]: 'shield-outline',
  [PROJECT_MEMBER_ROLES.STANDARD]: '',
};

export const TENANT_USER_ROLE_NAMES = {
  [TENANT_USER_ROLES.OWNER]: 'Owner',
  [TENANT_USER_ROLES.ADMIN]: 'Admin',
  [TENANT_USER_ROLES.STANDARD]: 'Standard',
};
