import { Injectable } from '@angular/core';

import { Tenant, PgListRequest, ListRequest, ListResponse, Profile } from 'src/models';

import { ApiService } from './api.service';

const BASE_PATH = '/internal/tenants';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  constructor(private apiService: ApiService) {}

  async getTenant(): Promise<Tenant> {
    return this.apiService.getPromise('/me/tenant');
  }

  async getTenantById(id: string): Promise<Tenant> {
    return this.apiService.getPromise(`${BASE_PATH}/${id}`);
  }

  async getByEmailDomain(domain: string): Promise<{ subdomain: string }> {
    return this.apiService.getPromise(`/tenants/for-domain?domain=${domain}`);
  }

  async updateTenant(data: Partial<Tenant>): Promise<void> {
    return this.apiService.patchPromise('/me/tenant', data);
  }

  async updateTenantById(id: string, data: Partial<Tenant>): Promise<void> {
    return this.apiService.patchPromise(`${BASE_PATH}/${id}`, data);
  }

  list(options: ListRequest = {}): Promise<ListResponse<Partial<Tenant>>> {
    return this.apiService.postPromise(`${BASE_PATH}/list`, options);
  }

  listUsers(tenantId: string | null, options: PgListRequest = {}): Promise<ListResponse<Partial<Profile>>> {
    return this.apiService.postPromise(`${BASE_PATH}/${tenantId}/users/list`, options);
  }

  async generateCustomToken(tenantId: string | null, userId: string): Promise<string> {
    const response = await this.apiService.postPromise<{ data: string }>(
      `${BASE_PATH}/${tenantId}/users/${userId}/generate-custom-token`,
      {},
    );

    return response.data;
  }

  createTenant(request: Record<string, unknown>): Promise<Tenant> {
    return this.apiService.postPromise(`${BASE_PATH}/`, request);
  }

  async resetFirebaseSettingsForUser(tenantId: string | null, userId: string): Promise<void> {
    await this.apiService.postPromise<{ data: string }>(
      `${BASE_PATH}/${tenantId}/users/${userId}/reset-firebase-settings`,
      {},
    );
  }

  async verifyEmailAddressForUser(tenantId: string | null, userId: string): Promise<void> {
    await this.apiService.postPromise<{ data: string }>(
      `${BASE_PATH}/${tenantId}/users/${userId}/verify-email-address`,
      {},
    );
  }

  async getWebhookConfig(): Promise<any> {
    return this.apiService.getPromise(`/me/tenant/webhook-config`);
  }

  async regenerateWebhookSecret(): Promise<void> {
    return this.apiService.postPromise(`/me/tenant/regenerate-webhook-secret`, {});
  }

  async enableAutomations(tenantId: string): Promise<void> {
    return this.apiService.postPromise(`${BASE_PATH}/${tenantId}/enable-custom-actions`, {});
  }
}
