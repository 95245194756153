export const or = parsers => {
  const {
    length
  } = parsers;
  return (text, position, rootParser) => {
    for (let i = 0; i < length; i++) {
      const match = parsers[i](text, position, rootParser);
      if (match) {
        return match;
      }
    }
    return null;
  };
};
export const oneOrMore = (parser, andThen) => {
  const rec = (text, position, rootParser) => {
    const match = parser(text, position, rootParser);
    if (!match) {
      return [];
    }
    const [, nextPosition] = match;
    return [match, ...rec(text, nextPosition, rootParser)];
  };
  return (text, position, rootParser) => {
    const ret = rec(text, position, rootParser);
    if (ret.length === 0) {
      return null;
    }
    const [, lastPosition] = ret[ret.length - 1];
    return [andThen(ret.map(([a]) => a)), lastPosition];
  };
};
export const regexp = (pattern, callback) => (text, position, parseText) => {
  const match = text.substring(position).match(pattern);
  if (!match) {
    return null;
  }
  return callback(match, text, position, parseText);
};
export const explicit = parser => (text, position, parseText) => {
  const prevChar = text.charAt(position - 1);
  if (prevChar && !prevChar.match(/[\s.,([{!?\-=]/)) {
    return null;
  }
  return parser(text, position, parseText);
};
export const topOfLine = parser => (text, position, parseText) => {
  if (position > 0 && text.charAt(position - 1) !== "\n") {
    return null;
  }
  return parser(text, position, parseText);
};