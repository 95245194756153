import { Injectable } from '@angular/core';
import { User, updateEmail } from '@angular/fire/auth';

import { ListRequest, ListResponse, Profile } from 'src/models';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apiService: ApiService) {}

  list(options: ListRequest = {}): Promise<ListResponse<Partial<Profile>>> {
    return this.apiService.postPromise('/users/list', options);
  }

  updateUser(userId: string, updates: Partial<Profile> = {}, isExternal: boolean = false) {
    return this.apiService.patchPromise(`/users/${userId}${isExternal ? '?isExternal=true' : ''}`, updates);
  }

  getUser(userId: string, isExternal: boolean = false): Promise<Profile> {
    return this.apiService.getPromise(`/users/${userId}${isExternal ? '?isExternal=true' : ''}`);
  }

  getMe() {
    return this.apiService.getPromise('/me');
  }

  setLastSeen() {
    return this.apiService.postPromise('/me/last-seen', {});
  }

  create(email: string, name?: string): Promise<Profile> {
    return this.apiService.postPromise(`/users`, { email, name });
  }

  // HACK: Ideally the email would be set in both firebase and the database from the backend but
  // there is an issue where changing the email on the backend will cause the user to be logged out
  // which is a bad UX since they just logged in for the first time.
  async updateEmail(userId: string, email: string, firebaseUser: User) {
    const [result] = await Promise.all([
      updateEmail(firebaseUser, email),
      this.apiService.putPromise(`/users/${userId}/email`, { email }),
    ]);

    return result;
  }

  getFieldValues(field: string) {
    return this.apiService.postPromise('/users/field-values', { field });
  }

  getProjectsForMe() {
    return this.apiService.getPromise('/me/projects');
  }
}
