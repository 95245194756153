import { NodeType } from "./types/Node.js";
import parser from "./parser.js";
const parseText = text => {
  const children = [];
  let textBuffer = "";
  const flush = () => {
    if (!textBuffer) {
      return;
    }
    children.push({
      type: NodeType.Text,
      text: textBuffer,
      source: textBuffer
    });
    textBuffer = "";
  };
  let i = 0;
  const l = text.length;
  while (i < l) {
    const match = parser(text, i, parseText);
    if (match) {
      flush();
      const [node, position] = match;
      children.push(node);
      i = position;
      continue;
    }
    textBuffer += text.charAt(i);
    i += 1;
  }
  flush();
  return children;
};
/**
 * Parses a Slack message and returns a parsed Node tree.
 *
 * @param message - a Slack message to parse
 * @returns Root node of the parsed message tree
 */
export const parse = message => {
  return {
    type: NodeType.Root,
    children: parseText(message),
    source: message
  };
};
export default parse;
export * from "./types/Node.js";